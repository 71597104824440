<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <user-name />
      <user-email />
      <b-row class="d-flex align-items-start">
        <b-col
          cols="12"
          md="auto"
        >
          <div class="pb-3">
            <b-card-text class="mb-0">
              Parolamı Güncelle
            </b-card-text>
            <b-form-checkbox
              id="changePassword"
              v-model="changePassword"
              name="check-button"
              switch
            />
          </div>
        </b-col>
        <b-col v-if="changePassword">
          <user-password />
        </b-col>
      </b-row>
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
    </b-card>
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import UserName from '@/views/Auth/elements/UserName.vue'
import UserEmail from '@/views/Auth/elements/UserEmail.vue'
import UserPassword from '@/views/Auth/elements/UserPassword.vue'
import {
  BCard, BFormCheckbox, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Profile',
  components: {
    BCard,
    BFormCheckbox,
    BRow,
    BCol,
    BCardText,
    UserName,
    UserEmail,
    UserPassword,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
      changePassword: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['profile/dataItem']
    },
    saveData() {
      return this.$store.getters['profile/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('profile/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('profile/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
